import React, { useState } from 'react'
import Modal from 'react-responsive-modal';
import IconBan from '../../../icons/cancel-ban'
import { IconTrashAlt } from '../../../icons/trash'
import IconWarning from '../../../icons/warning';
import { apiDriverApp } from '../../../../libs/apiDriverApp';
import { ModalGeneric } from './ModalGeneric';

export const ModalDelete = ({ showModal, handleCloseModal, item, getShifts, title, text, deleteType = '' }) => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleDeleteShift = async () => {
        await apiDriverApp.delete(`api/hr/shifts/${item.id}/`);
        handleCloseModal()
        setShowConfirmation(true)
        getShifts()
    }

    const handleDeleteSchedule = ()=>{
        handleCloseModal()
    }

    return (
        <React.Fragment>
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                showCloseIcon={false}
                styles={{ modal: { minWidth: '25%', borderRadius: '8px', marginTop: 50, padding:0 } }}
            >
                <div className="d-flex row m-0 justify-content-between align-items-center pt-3">
                    <div className='col-6'>
                        <h4 style={{ fontWeight: 'bold' }}>{title}</h4>
                    </div>

                    <div className="d-flex col-6 justify-content-center align-items-center">

                        <button onClick={handleCloseModal} className="btn border border-dark d-flex align-items-center rounded shadow-sm px-3 py-2 me-3">
                            <div style={{ display: 'flex', alignItems: 'center', height: '0.7rem' }}>
                                <IconBan className='h-100' />
                            </div>
                            <span className="ml-1" style={{ fontWeight: 'bold' }}>Close</span>
                        </button>

                        <button onClick={ deleteType === 'schedule' ? handleDeleteSchedule : handleDeleteShift} className="btn rounded shadow-sm px-3 py-2 d-flex align-items-center ml-3" style={{ backgroundColor: '#D50032', color: 'white' }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                                <IconTrashAlt className='h-100' />
                            </div>
                            <span className="ml-1" >Delete</span>
                        </button>
                    </div>


                </div>

                <hr />

                <div className="container px-5 py-2">
                    <div className="row mb-3 justify-content-center">
                        <div style={{ marginTop: 4 }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '1rem', color: '#FF6C00' }}>
                                <IconWarning className='h-100' />
                            </div>
                        </div>

                        <div style={{ width: 350, marginLeft: 10 }}>
                           {text}
                        </div>
                    </div>
                </div>

            </Modal>
            <ModalGeneric
                title={'Delete Driver Shift'}
                text={'Shift deleted successfully.'}
                showModal={showConfirmation}
                handleCloseModal={()=>setShowConfirmation(false)}
            />
        </React.Fragment>

    )
}

