import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import XLSX from "xlsx";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";


const LOADING = "LOADING_CREW_ADMIN";
const LOADING_FORM = "LOADING_UPDATE_FORM";
const SETTINGS_LOADING = "SETTINGS_LOADING";
const SET_SETTINGS = "SET_SETTINGS";
const SET_AIRLINE_DATA = "SET_AIRLINE_DATA";
const SET_PAGE_AIRLINE = "SET_PAGE_AIRLINE";
const SET_AIRLINE_ITEM = "SET_AIRLINE_ITEM";
const SET_SEARCH_AIRLINE = "SET_SEARCH_AIRLINE";
const SET_ACITVE_TAB = "SET_ACITVE_TAB_AIRLINE";

export const setLoadingSettings = (settingsLoading) => ({
    type: SETTINGS_LOADING,
    settingsLoading,
});
export const setAirlineSettings = (settings) => ({
    type: SET_SETTINGS,
    settings,
});

export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = { query: {
            $sort: { company: 1, code: 1 },
            $limit: 5,
            $skip: (page - 1) * 5,
            // $collation: { locale: "en" }
        }};
        const search = getStore().crewAdmin.airlines.search;
        if(search){
            params.query.$or = [
                { company: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { code: { $regex: _.escapeRegExp(search), $options: 'i' }},
            ]

            if(!isNaN(parseInt(search))){
                params.query.$or.push({ liveryId: parseInt(search) });
            }
        }

        const data = await app.service('/api/airline').find(params);
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data) => async (dispatch) => {
    dispatch(setLoadingForm(true));
    try {
        await app.service('/api/airline').create(data);
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let params = { query: { $populate: ['sharedCodes'] } };
        let data = await app.service('/api/airline').get(id, params);
        dispatch(initializeForm('airlineForm', data));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const getAirline = (id) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        let params = { query: { $populate: ['sharedCodes'] } };
        let data = await app.service('/api/airline').get(id, params);
        const totalRoutes = await app.service('/api/airline_route').find({ query: { airline: id, $select: ['_id'] }});
        data.totalRoutes = totalRoutes.total;
        dispatch(setItem(data));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
}

export const update = (data) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    try{
        await app.service('/api/airline').patch(data._id, data);
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const loadSettings2Update = (id) => async (dispatch) => {
    dispatch(setLoadingSettings(true));
    try {
        const response = await app
            .service("/api/airline_settings")
            .get(id, { query: { $populate: ["airport"] } });
        response.airport.label = `${response.airport.code} - ${response.airport.name}`;
        dispatch(initializeForm("airlineSettings", response));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingSettings(false));
    }
}

export const getAirlineSettings = (airline) => async (dispatch) => {
    dispatch(setLoadingSettings(true));
    try {
        const response = await app.service("/api/airline_settings").find({
            query: {
                airline,
                $populate: ["airport"],
            },
        });
        dispatch(setAirlineSettings(response));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingSettings(false));
    }
};

export const createSettings = (data) => async (dispatch, getStore) => {
    dispatch(setLoadingSettings(true));
    const { item } = getStore().crewAdmin.airlines;
    try {
        await app.service("/api/airline_settings").create(data);
        dispatch(getAirlineSettings(item._id));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingSettings(false));
    }
};

export const updateSettings = (id, data) => async (dispatch, getStore) => {
    dispatch(setLoadingSettings(true));
    const { item } = getStore().crewAdmin.airlines;
    try {
        await app.service("/api/airline_settings").patch(id, data);
        dispatch(getAirlineSettings(item._id));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingSettings(false));
    }
};

export const removeSettings = (id) => async (dispatch, getStore) => {
    swal.fire({
        type: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#D50032",
        confirmButtonText: "REMOVE",
        cancelButtonText: "CANCEL",
    }).then(async ({ value }) => {
        if (value) {
            dispatch(setLoadingSettings(true));
            const { item } = getStore().crewAdmin.airlines;
            try {
                await app.service("/api/airline_settings").remove(id);
                dispatch(getAirlineSettings(item._id));
            } catch (error) {
                dispatch(errorHandler(error));
            } finally {
                dispatch(setLoadingSettings(false));
            }
        }
    });
};

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let { page } = getStore().crewAdmin.airlines;
    try {
        await app.service("/api/airline").remove(id);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setSearch = (search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH_AIRLINE, search });
    dispatch(find());
};

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE_AIRLINE, page });
export const setData = (data) => ({ type: SET_AIRLINE_DATA, data });
export const setItem = (item) => ({ type: SET_AIRLINE_ITEM, item });
export const setActiveTab = (activeTab) => ({ type: SET_ACITVE_TAB, activeTab });

export const downloadRoutesReport = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const result = await app.service("/api/airline_route").find({
            query: {
                futurePricingReport: true,
            },
        });

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Future Pricing",
            Subject: "Airline Routes",
            Author: "BBC EXPRESS",
            CreatedDate: new Date(),
        };

        // Head of workbook in spreadsheet
        const wsData = [["Route", "Vehicle", "Effective Date", "Distance", "Travel time", "Max pax", "Price", "Farm out rate", "Min driver duration", "New Price",
            "cxl_policy", "airport_pu", "airport_do", "isAOA", "isCGO", "account_group", "is_future_price", "is_contract"]];

        // Create the array of data for add to Spreadsheet
        for (let index = 0; index < result.length; index += 1)
            wsData.push([
                result[index].route,
                result[index].vehicle,
                result[index].effective_date,
                result[index].distance,
                result[index].travel_time,
                result[index].max_pax,
                result[index].price,
                result[index].farm_out_rate,
                result[index].min_drive_duration,
                result[index].new_price,
                result[index].cxl_policy,
                result[index].airport_pu,
                result[index].airport_do,
                result[index].isAOA,
                result[index].isCGO,
                JSON.stringify(result[index].account_group),
                result[index].is_future_price,
                result[index].is_contract
            ]);
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Columns width in spreadsheet
        const wscols = [{wch:17}, {wch:12}, {wch:12}, {wch:10}, {wch:10}, {wch:10}, {wch:10}, {wch:12}, {wch:14}, {wch:10}];
        ws["!cols"] = wscols;

        // Add the data to new sheet in the spreadsheet
        XLSX.utils.book_append_sheet(wb, ws, "Future Pricing");
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
            const view = new Uint8Array(buf); // create uint8array as viewer
            for (let i = 0; i < s.length; i += 1)
                view[i] = s.charCodeAt(i) & 0xff; // convert to octet
            return buf;
        };

        // Save the spreadsheet
        saveAs(
            new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
            `Future Pricing.xlsx`
        );
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
}

export const actions = {
    find,
    create,
    setSearch,
    getAirline,
    load2Update,
    loadSettings2Update,
    getAirlineSettings,
    createSettings,
    updateSettings,
    removeSettings,
    setActiveTab,
    downloadRoutesReport,
    update,
    destroy,
};

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE_AIRLINE]: (state, { page }) => ({ ...state, page }),
    [SET_AIRLINE_DATA]: (state, { data }) => ({ ...state, data }),
    [SETTINGS_LOADING]: (state, { settingsLoading }) => ({ ...state, settingsLoading }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH_AIRLINE]: (state, { search }) => ({ ...state, search }),
    [SET_SETTINGS]: (state, { settings }) => ({ ...state, settings }),
    [SET_AIRLINE_ITEM]: (state, { item }) => ({ ...state, item }),
    [SET_ACITVE_TAB]: (state, { activeTab }) => ({ ...state, activeTab }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    settingsLoading: false,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: undefined,
    settings: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    search: undefined,
    activeTab: 0,
};

export default handleActions(reducers, initialState);
