import React, {useState, useEffect} from 'react'
import Select from "react-select";

export const CustomHoursSelect = ({ week, day, onChange, name }) => {

    const [values, setValues] = useState({value1:'', value2:''});
  
    const options = [
        { label: '00:00', value: 0.0 },
        { label: '00:15', value: 0.25 },
        { label: '00:30', value: 0.5 },
        { label: '00:45', value: 0.75 },
        { label: '01:00', value: 1.0 },
        { label: '01:15', value: 1.25 },
        { label: '01:30', value: 1.5 },
        { label: '01:45', value: 1.75 },
        { label: '02:00', value: 2.0 },
        { label: '02:15', value: 2.25 },
        { label: '02:30', value: 2.5 },
        { label: '02:45', value: 2.75 },
        { label: '03:00', value: 3.0 },
        { label: '03:15', value: 3.25 },
        { label: '03:30', value: 3.5 },
        { label: '03:45', value: 3.75 },
        { label: '04:00', value: 4.0 },
        { label: '04:15', value: 4.25 },
        { label: '04:30', value: 4.5 },
        { label: '04:45', value: 4.75 },
        { label: '05:00', value: 5.0 },
        { label: '05:15', value: 5.25 },
        { label: '05:30', value: 5.5 },
        { label: '05:45', value: 5.75 },
        { label: '06:00', value: 6.0 },
        { label: '06:15', value: 6.25 },
        { label: '06:30', value: 6.5 },
        { label: '06:45', value: 6.75 },
        { label: '07:00', value: 7.0 },
        { label: '07:15', value: 7.25 },
        { label: '07:30', value: 7.5 },
        { label: '07:45', value: 7.75 },
        { label: '08:00', value: 8.0 },
        { label: '08:15', value: 8.25 },
        { label: '08:30', value: 8.5 },
        { label: '08:45', value: 8.75 },
        { label: '09:00', value: 9.0 },
        { label: '09:15', value: 9.25 },
        { label: '09:30', value: 9.5 },
        { label: '09:45', value: 9.75 },
        { label: '10:00', value: 10.0 },
        { label: '10:15', value: 10.25 },
        { label: '10:30', value: 10.5 },
        { label: '10:45', value: 10.75 },
        { label: '11:00', value: 11.0 },
        { label: '11:15', value: 11.25 },
        { label: '11:30', value: 11.5 },
        { label: '11:45', value: 11.75 },
        { label: '12:00', value: 12.0 },
        { label: '12:15', value: 12.25 },
        { label: '12:30', value: 12.5 },
        { label: '12:45', value: 12.75 },
        { label: '13:00', value: 13.0 },
        { label: '13:15', value: 13.25 },
        { label: '13:30', value: 13.5 },
        { label: '13:45', value: 13.75 },
        { label: '14:00', value: 14.0 },
        { label: '14:15', value: 14.25 },
        { label: '14:30', value: 14.5 },
        { label: '14:45', value: 14.75 },
        { label: '15:00', value: 15.0 },
        { label: '15:15', value: 15.25 },
        { label: '15:30', value: 15.5 },
        { label: '15:45', value: 15.75 },
        { label: '16:00', value: 16.0 },
        { label: '16:15', value: 16.25 },
        { label: '16:30', value: 16.5 },
        { label: '16:45', value: 16.75 },
        { label: '17:00', value: 17.0 },
        { label: '17:15', value: 17.25 },
        { label: '17:30', value: 17.5 },
        { label: '17:45', value: 17.75 },
        { label: '18:00', value: 18.0 },
        { label: '18:15', value: 18.25 },
        { label: '18:30', value: 18.5 },
        { label: '18:45', value: 18.75 },
        { label: '19:00', value: 19.0 },
        { label: '19:15', value: 19.25 },
        { label: '19:30', value: 19.5 },
        { label: '19:45', value: 19.75 },
        { label: '20:00', value: 20.0 },
        { label: '20:15', value: 20.25 },
        { label: '20:30', value: 20.5 },
        { label: '20:45', value: 20.75 },
        { label: '21:00', value: 21.0 },
        { label: '21:15', value: 21.25 },
        { label: '21:30', value: 21.5 },
        { label: '21:45', value: 21.75 },
        { label: '22:00', value: 22.0 },
        { label: '22:15', value: 22.25 },
        { label: '22:30', value: 22.5 },
        { label: '22:45', value: 22.75 },
        { label: '23:00', value: 23.0 },
        { label: '23:15', value: 23.25 },
        { label: '23:30', value: 23.5 },
        { label: '23:45', value: 23.75 },
      ]

      const handleChange1 = (selectedOption) => {
        const newValues = { ...values, value1: selectedOption };
        setValues(newValues);
        updateString(newValues); 
    };

    const handleChange2 = (selectedOption) => {
        const newValues = { ...values, value2: selectedOption };
        setValues(newValues);
        updateString(newValues); 
    };

    const updateString = (newValues) => {
        if (newValues.value1 && newValues.value2) {
            const newString = `${newValues.value1.label}-${newValues.value2.label}`;
            onChange(week, day, newString); 
        }
    };

      useEffect(() => {
        if (name) {
            const [time1, time2] = name.split('-'); 
            setValues({
                value1: options.find(option => option.label === time1) || null, 
                value2: options.find(option => option.label === time2) || null, 
            });
        }
    }, [name]);
      
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
                className='form-control select-reset p-0'
                options={options}
                value={values.value1}
                onChange={handleChange1}
                placeholder={''}
                clearable={false}
                arrowRenderer={() => null} 
                style={{
                    indicatorsContainer: { display: 'none' }, 
                }}
            />

            <span style={{ margin: '0 2px' }}> - </span>

            <Select
                className='form-control select-reset p-0'
                options={options}
                value={values.value2}
                onChange={handleChange2}
                placeholder={''}
                clearable={false}
                arrowRenderer={() => null} 
                style={{
                    indicatorsContainer: { display: 'none' },
                }}
            />
        </div>

    )
}
