import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";
import XLSX from "xlsx";
import { saveAs } from "file-saver";


const LOADING = "LOADING_CREW_ADMIN";
const LOADING_FORM = "LOADING_UPDATE_FORM";
const SET_AIRPORT_DATA = "SET_AIRPORT_DATA";
const SET_PAGE_AIRPORT = "SET_PAGE_AIRPORT";
const ITEM_AIRPORT = "ITEM_AIRPORT";
const SET_SEARCH_AIRPORT = "SET_SEARCH_AIRPORT";
const AIRPORT_ADDRESS = "AIRPORT_ADDRESS";
const AIRPORT_ADDRESS_2 = "AIRPORT_ADDRESS_2";


export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $sort: { name: 1, code: 1 },
            $limit: 5,
            $skip: (page - 1) * 5,
            // $collation: { locale: "en" }
        }};
        const search = getStore().crewAdmin.airports.search;
        if(search){
            params.query.$or = [
                { name: { $regex:  _.escapeRegExp(search), $options: 'i' }},
                { description: { $regex:  _.escapeRegExp(search), $options: 'i' }},
                { code: { $regex:  _.escapeRegExp(search), $options: 'i' }}
            ]
        }

        const data = await app.service('/api/airport').find(params);
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (values) => async (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    let { page, address = {}, address2 } = getStore().crewAdmin.airports;
    try {
        const{ state, postal_code, city, lat, lng } = address;
        let data = Object.assign({}, { ...values, lat, lng, state, zip: postal_code, city, street: `${address.street_number} ${address.route}`, address2 });
        await app.service('/api/airport').create(data);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let data = await app.service('/api/airport').get(id);
        await dispatch(initializeForm('airportForm', data));
        dispatch(setItem({
            ...data,
            address: `${data.street}, ${data.city}, ${data.state} ${data.zip}`,
            address2: data.address2
                ? `${data.address2.street_number ? data.address2.street_number : ''}${data.address2.route ? ` ${data.address2.route}, ` : ''}${data.address2.city}, ${data.address2.state}${data.address2.postal_code ? ` ${data.address2.postal_code}` : ''}`
                : ''
        }));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (values) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    let { page, address = {}, address2 } = getStore().crewAdmin.airports;
    try {
        const{ state, postal_code, city, lat, lng } = address;
        let data = Object.assign({}, { ...values, lat, lng, state, zip: postal_code, city, street: `${address.street_number} ${address.route}`, address2 });
        await app.service('/api/airport').update(data._id, data);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let { page } = getStore().crewAdmin.airports;
    try {
        await app.service('/api/airport').remove(id);
        dispatch(find(page))
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const airlineReport = () => async dispatch => {
    dispatch(setLoading(true));

    try {
        const all_airports = await app.service('/api/airport').find({
            query: {
                $paginate: false
            }
        })
        const result = [];
        for(let i=0; i<all_airports.length; i+=1) {
            const data = all_airports[i];
            const google_place = await app.service('/api/googleMaps').find({
                query: {
                    search: data.description || data.name,
                    options: {
                        types: "airport"
                    }
                }
            });
            if (google_place && google_place.predictions && google_place.predictions.length > 0) {
                data.google_place_id = google_place.predictions[0].place_id
            }
            result.push(data)
        }

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Airports",
            Subject: "Airports",
            Author: "BBC EXPRESS",
            CreatedDate: new Date(),
        };

        // Head of workbook in spreadsheet
        const wsData = [["code", "name", "description", "street", "city", "state", "zip", "timeZone", "lat", "lng", "google_place_id"]];

        // Create the array of data for add to Spreadsheet
        for (let index = 0; index < result.length; index += 1)
            wsData.push([
                result[index].code,
                result[index].name,
                result[index].description,
                result[index].street,
                result[index].city,
                result[index].state,
                result[index].zip,
                result[index].timeZone,
                result[index].lat,
                result[index].lng,
                result[index].google_place_id
            ]);
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Columns width in spreadsheet
        const wscols = [{wch:10}, {wch:15}, {wch:20}, {wch:15}, {wch:15}, {wch:10}, {wch:10}, {wch:15}, {wch:15}, {wch:15}];
        ws["!cols"] = wscols;

        // Add the data to new sheet in the spreadsheet
        XLSX.utils.book_append_sheet(wb, ws, "Airports");
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
            const view = new Uint8Array(buf); // create uint8array as viewer
            for (let i = 0; i < s.length; i += 1)
                view[i] = s.charCodeAt(i) & 0xff; // convert to octet
            return buf;
        };

        // Save the spreadsheet
        saveAs(
            new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
            `Airports.xlsx`
        );

    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setDetails = (address, address2) => dispatch => {
    dispatch({ type: address2 ? AIRPORT_ADDRESS_2 : AIRPORT_ADDRESS, address });
};

export const setSearch = (search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH_AIRPORT, search });
    dispatch(find());
};

export const clearData = () => (dispatch) => {
    dispatch(setDetails());
    dispatch(setItem({}));
};

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE_AIRPORT, page });
export const setData = (data) => ({ type: SET_AIRPORT_DATA, data });
export const setItem = (item) => ({ type: ITEM_AIRPORT, item });

export const actions = {
    find,
    create,
    clearData,
    airlineReport,
    setSearch,
    setDetails,
    load2Update,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE_AIRPORT]: (state, { page }) => ({ ...state, page }),
    [SET_AIRPORT_DATA]: (state, { data }) => ({ ...state, data }),
    [ITEM_AIRPORT]: (state, { item }) => ({ ...state, item }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH_AIRPORT]: (state, { search }) => ({...state, search }),
    [AIRPORT_ADDRESS]: (state, { address }) => ({ ...state, address }),
    [AIRPORT_ADDRESS_2]: (state, { address }) => ({ ...state, address2: address }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    search: undefined,
    address: undefined,
    address2: undefined,
};

export default handleActions(reducers, initialState);
