import React from 'react'
import "./styles.css"; // Agrega tus estilos personalizados aquí

export const Calendar = ({ schedule }) => {
  const weeks = Object.keys(schedule); 
  const days = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]; 

  return (
    <div>
      <table className="table table-borderless align-middle text-center">
        <thead>
          <tr>
            <th>WK</th>
            {days.map((day, index) => (
              <th key={index}>{day.slice(0, 3)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, weekIndex) => (
            <tr key={weekIndex}>
              <td className="fw-bold">{week.split(" ")[1]}</td>
              {days.map((day, dayIndex) => (
                <td key={dayIndex}>
                  <div
                    className={`circle ${
                      (schedule[week][day] && schedule[week][day].name && schedule[week][day].name !== 'None') ? "circle-filled" : "circle-empty"
                    }`}
                  ></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
