import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-responsive-modal';
import IconFloppyDisk from '../../../icons/floppy-disk';
import CalendarDayRounded from '../../../icons/calendar-day-rounded';
import RotateArrow from '../../../icons/rotate-arrow'
import { SingleDatePicker } from 'react-dates';
import Select from "react-select";
import swal from 'sweetalert2';
import moment from 'moment';
import { WeeklySchedule } from './WeeklySchedule';
import './styles.css';
import IconX from '../../../icons/x-icon';
import { Calendar } from './calendar/Calendar'
import { apiDriverApp } from '../../../../libs/apiDriverApp';
import { ModalGeneric } from './ModalGeneric';

export const ModalScheduleForm = ({ showModal, handleCloseModal, initialValues, pilots, options, isCopy, getSchedules, resetPilots }) => {

    const [formValues, setFormValues] = useState({
        title: '',
        recurringSchedule: 1,
        startDate: moment(),
        is_indefinite: true,
        endDate: null,
        weeks: [],
        drivers: []
    });

    const [focused, setFocused] = useState(false);
    const [focused2, setFocused2] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [showInnerModal, setShowInnerModal] = useState(false);
    const [showNoti, setShowNoti] = useState(false);

    const initialSchedule = {
        "Week 1": {
            Saturday: { name: "" },
            Sunday: { name: "" },
            Monday: { name: "" },
            Tuesday: { name: "" },
            Wednesday: { name: "" },
            Thursday: { name: "" },
            Friday: { name: "" },
        },
    };

    const [schedule, setSchedule] = useState(initialSchedule);

    const openInnerModal = () => {
        setShowInnerModal(true);
    };

    const handleChange = (name, value) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === "recurringSchedule") {
            const newNumberOfWeeks = parseInt(value, 10);

            setSchedule((prevSchedule) => {
                const existingWeeks = Object.entries(prevSchedule);
                const newSchedule = {};

                for (let i = 1; i <= newNumberOfWeeks; i++) {
                    const weekKey = `Week ${i}`;
                    if (prevSchedule[weekKey]) {
                        newSchedule[weekKey] = prevSchedule[weekKey];
                    } else {
                        newSchedule[weekKey] = {
                            Saturday: { name: "" },
                            Sunday: { name: "" },
                            Monday: { name: "" },
                            Tuesday: { name: "" },
                            Wednesday: { name: "" },
                            Thursday: { name: "" },
                            Friday: { name: "" },
                        };
                    }
                }

                return newSchedule;
            });
        }
    };


    const [selectedPilots, setSelectedPilots] = useState([]);
    const [search, setSearch] = useState("");

    const availablePilots = pilots.filter(
        (pilot) => !selectedPilots.includes(pilot.name) && pilot.name.toLowerCase().includes(search.toLowerCase())
    );

    const togglePilot = (pilot) => {
        setSelectedPilots((prev) =>
            prev.some((p) => p.resource_id === pilot.resource_id)
                ? prev.filter((p) => p.resource_id !== pilot.resource_id)
                : [...prev, pilot]
        );
    };

    const weeks = [
        { label: '1 weeks', value: 1 },
        { label: '2 weeks', value: 2 },
        { label: '3 weeks', value: 3 },
        { label: '4 weeks', value: 4 },
        { label: '5 weeks', value: 5 },
        { label: '6 weeks', value: 6 },
        { label: '7 weeks', value: 7 },
        { label: '8 weeks', value: 8 },
        { label: '9 weeks', value: 9 },
    ]

    const transformWeeks = (weeks) => {
        const dayMapping = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
        return weeks.reduce((result, week) => {
            const weekName = `Week ${week.week_type + 1}`;
            result[weekName] = week.days.reduce((daysResult, day) => {
                const dayName = dayMapping[day.dayofweek]; // Obtenemos el nombre del día
                daysResult[dayName] = day.shifts.length > 0
                    ? { name: day.shifts[0].name, color: day.shifts[0].color } // Incluye el nombre y el color
                    : { name: "", color: "" }; // Valores por defecto si no hay turnos
                return daysResult;
            }, {});
            return result;
        }, {});
    };

    const days = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];


    const transformSchedule = (initialSchedule) => {
        return Object.values(initialSchedule).map((week) => {
            const transformedWeek = {};
            Object.entries(week).forEach(([day, value]) => {
                transformedWeek[day.toLowerCase()] =
                    value && value.name && value.name !== "None"
                        ? [value.name || null]
                        : [];
            });
            return transformedWeek;
        });
    };


    useEffect(() => {
        if (initialValues) {
            setFormValues({
                title: initialValues.name,
                recurringSchedule: initialValues.rotation_weeks,
                startDate: initialValues.start_date ? moment(initialValues.start_date) : null,
                is_indefinite: initialValues.is_indefinite,
                endDate: initialValues.end_date ? moment(initialValues.end_date) : null,
                weeks: initialValues.weeks,
                drivers: initialValues.drivers
            });
            setSelectedPilots(initialValues.drivers)
            setSchedule(transformWeeks(initialValues.weeks))
        } else {
            setFormValues({
                title: '',
                recurringSchedule: 1,
                startDate: moment(),
                is_indefinite: true,
                endDate: null,
                weeks: [],
                drivers: []
            });
            setSelectedPilots([])
            setSchedule(initialSchedule)
        }
    }, [initialValues])

    const updateScheduleResources = async (scheduleId, updatedDrivers) => {
        const resourceIds = updatedDrivers.map((pilot) => pilot.resource_id);
        try {
            await apiDriverApp.post(`api/hr/schedule/${scheduleId}/manage_resources/`, {
                resource_ids: resourceIds
            })
        } catch (error) {
            console.log(error)
        }
    }

    const convertTimeToDecimal = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours + minutes / 60;
    }

    const createNonExistingShifts = async (shifts2create) => {
        
        const uniqueShifts = new Map();
        shifts2create.forEach(item => {
            const [hourFrom, hourTo] = item.shiftName.split('-');
            if (!hourFrom || !hourTo) {
                console.error(`Invalid format on shiftName: ${item.shiftName}`);
                return;
            }
    
            const shift = {
                name: item.shiftName,
                hour_from: convertTimeToDecimal(hourFrom),
                hour_to: convertTimeToDecimal(hourTo)
            };
    
            const key = `${shift.name}-${shift.hour_from}-${shift.hour_to}`;
            if (!uniqueShifts.has(key)) {
                uniqueShifts.set(key, shift);
            }
        });
    
        const requests = Array.from(uniqueShifts.values()).map(newShift => {
            return apiDriverApp.post('/api/hr/shifts/', {
                name: newShift.name,
                hour_from: newShift.hour_from,
                hour_to: newShift.hour_to
            });
        });
    
        try {
            await Promise.all(requests);
            // console.log("Todas las solicitudes fueron exitosas.");
        } catch (error) {
            console.error("Error al crear los turnos:", error);
        }
    };
    
    

    const handleSubmit = async () => {
        if (!formValues || formValues.title === '' || !formValues.startDate) {
            swal("Empty Fields", 'Please verify that all fields are filled', "error");
            return;
        }

        const updatedSchedule = transformSchedule(schedule)

        const { data: dbShifts } = await apiDriverApp.get('api/hr/shifts/?shift_type=all');
        const databaseShiftNames = dbShifts.map(shift => shift.name);

        const shiftsToCreate = updatedSchedule.flatMap(shiftObject => {
            return Object.entries(shiftObject).flatMap(([day, shifts]) => {
                const shiftsArray = Array.isArray(shifts) ? shifts : [shifts];
                return shiftsArray.filter(shiftName => !databaseShiftNames.includes(shiftName))
                    .map(shiftName => ({ day, shiftName }));
            });
        });
        await createNonExistingShifts(shiftsToCreate)

        try {
            if (isCopy) {
               
                const body = {
                    id: null,
                    calendar_name: formValues.title,
                    number_of_weeks: formValues.recurringSchedule,
                    start_date: formValues.startDate.format('YYYY-MM-DD'),
                    is_indefinite: formValues.is_indefinite,
                    end_date: formValues.endDate ? formValues.endDate.format('YYYY-MM-DD') : null,
                    weeks: updatedSchedule
                }
               
                const { data } = await apiDriverApp.post(`api/hr/schedule/manage_calendar/`, body);

            } else {
                
                const body = {
                    id: initialValues.id,
                    calendar_name: formValues.title,
                    number_of_weeks: formValues.recurringSchedule,
                    start_date: formValues.startDate.format('YYYY-MM-DD'),
                    is_indefinite: formValues.is_indefinite,
                    end_date: formValues.endDate ? formValues.endDate.format('YYYY-MM-DD') : null,
                    weeks: updatedSchedule
                }
                
                const { data } = await apiDriverApp.post(`api/hr/schedule/manage_calendar/`, body);
                await updateScheduleResources(data.calendar.id, selectedPilots);

            }

            setShowNoti(true);
            getSchedules();
            resetPilots();
            handleCloseModal();
        } catch (error) {
            console.error("Error during request:", error.response);

            if (error.response) {
                swal("Error", error.response.data.error || "An error occurred", "error");
            } else if (error.request) {
                swal("Network Error", "No response from server. Please try again later.", "error");
            } else {
                swal("Error", "An unexpected error occurred", "error");
            }
        }
    };


    return (
        <React.Fragment>
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                showCloseIcon={false}
                styles={{
                    modal: {
                        minWidth: '35%',
                        borderRadius: '8px',
                        marginTop: 50,
                        padding: 0,
                        maxWidth: '650px'

                    }
                }}
            >

                <div className="d-flex justify-content-between align-items-center px-4 pt-3">
                    <div>
                        <h3 style={{ fontWeight: 'bold' }}>Edit Schedule</h3>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">

                        <button onClick={handleCloseModal} className="btn d-flex align-items-center rounded shadow-sm px-3 py-2 me-3" style={{ backgroundColor: '#D50032', color: 'white' }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                                X
                            </div>
                            <span className="ml-1">Close</span>
                        </button>

                        <button onClick={handleSubmit} className="btn border border-dark rounded shadow-sm px-3 py-2 d-flex align-items-center ml-3">
                            <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                                <IconFloppyDisk className='h-100' />
                            </div>
                            <span className="ml-1" style={{ fontWeight: 'bold' }}>Save</span>
                        </button>
                    </div>


                </div>

                <hr />

                <div className='px-5'>
                    <div className="d-flex justify-content-between align-items-center px-5 pt-3 mb-3">
                        <div>

                            <button
                                className={`btn border border-dark rounded ${activeTab === 0 ? 'btn-active' : ''}`}
                                style={{ color: 'white', backgroundColor: 'black' }}
                                onClick={() => setActiveTab(0)}
                            >
                                Schedules
                            </button>

                            <button
                                className={`btn border border-dark rounded ml-3 ${activeTab === 1 ? 'btn-active' : ''}`}
                                style={{ color: 'white', backgroundColor: 'black' }}
                                onClick={() => setActiveTab(1)}
                            >
                                Drivers
                            </button>

                        </div>

                        <button className='btn-hover' onClick={openInnerModal}>
                            <div className='row'>
                                <div style={{ height: '1rem' }}>
                                    <RotateArrow className="h-100" />
                                </div>
                                <div style={{ marginLeft: 5 }}>Load Calendar</div>
                            </div>
                        </button>
                    </div>

                    <div className="container px-5">

                        {
                            (activeTab === 0) && (
                                <div>
                                    <div className="row mb-3">
                                        <div className='col-12'>
                                            <h6>Title</h6>
                                            <input
                                                placeholder='Midday'
                                                className='form-control'
                                                value={formValues.title}
                                                onChange={(e) => handleChange('title', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <h6>Recurring Schedule</h6>
                                            <Select
                                                options={weeks}
                                                value={formValues.recurringSchedule}
                                                onChange={(option) => handleChange('recurringSchedule', option.value)}
                                                placeholder="1 week"
                                            />
                                        </div>
                                        <div className="col-md-6 ">
                                            <h6>Start Date</h6>
                                            <div className='form-control p-1 d-flex flex-row align-items-center'>
                                                <SingleDatePicker
                                                    id="date_input"
                                                    date={formValues.startDate}
                                                    onDateChange={(newDate) => handleChange('startDate', newDate)}
                                                    focused={focused}
                                                    onFocusChange={({ focused }) => setFocused(focused)}
                                                    numberOfMonths={1}
                                                    isOutsideRange={() => false}
                                                />
                                                <div style={{ display: 'flex', alignItems: 'center', height: '1rem', marginRight: 6 }}>
                                                    <CalendarDayRounded className='h-100' />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row mb-3 align-items-center col-8">
                                        <span className="mr-2">Ends</span>

                                        <div className="form-check form-check-inline">
                                            <input
                                                type="radio"
                                                id="never"
                                                name="ends"
                                                value="never"
                                                checked={formValues.is_indefinite === true}
                                                onChange={() => handleChange("is_indefinite", true)}
                                                className="form-check-input"
                                                style={{ width: "1.2rem", height: "1.2rem", borderRadius: "50%" }}
                                            />
                                            <label htmlFor="never" className="form-check-label">
                                                Never
                                            </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input
                                                type="radio"
                                                id="on"
                                                name="ends"
                                                value="on"
                                                checked={formValues.is_indefinite === false} // Relaciona con el estado
                                                onChange={() => handleChange("is_indefinite", false)} // Actualiza el estado
                                                className="form-check-input"
                                                style={{ width: "1.2rem", height: "1.2rem", borderRadius: "50%" }}
                                            />
                                            <label htmlFor="on" className="form-check-label">
                                                On
                                            </label>
                                        </div>
                                    </div>

                                    {formValues.is_indefinite === false && (
                                        <div className="row mb-3 align-items-center col-6">
                                            <div className='form-control p-1 d-flex flex-row align-items-center'>
                                                <SingleDatePicker
                                                    id="date_input"
                                                    date={formValues.endDate}
                                                    onDateChange={(newDate) => handleChange('endDate', newDate)}
                                                    focused={focused2}
                                                    onFocusChange={({ focused }) => setFocused2(focused)}
                                                    numberOfMonths={1}
                                                    isOutsideRange={() => false}
                                                />
                                                <div style={{ display: 'flex', alignItems: 'center', height: '1rem', marginRight: 6 }}>
                                                    <CalendarDayRounded className='h-100' />
                                                </div>
                                            </div>
                                        </div>
                                    )

                                    }

                                    <div className="row mb-3">
                                        <div
                                            className="col-12"
                                            style={{
                                                maxHeight: '500px',
                                                overflowY: 'auto',
                                                // padding: '8px',
                                                scrollbarWidth: 'none'
                                            }}
                                        >
                                            <WeeklySchedule schedule={schedule} setSchedule={setSchedule} options={options} isOpen={showModal} />
                                        </div>
                                    </div>
                                </div>

                            )
                        }

                        {
                            (activeTab === 1) && (
                                <div>
                                    {/* Search Bar */}
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <input
                                                placeholder="Search..."
                                                className="form-control"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* Selected Pilots */}
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="container">
                                                <div className="border row justify-content-between">
                                                    {formValues.drivers.map((pilot, index) => (
                                                        <div key={index} className="col-6 p-1">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={selectedPilots.some((p) => p.employee_id === pilot.employee_id)}
                                                                    onChange={() => togglePilot(pilot)}
                                                                    id={`pilot-${index}`}
                                                                />
                                                                <label className="form-check-label" htmlFor={`pilot-${index}`}>
                                                                    {pilot.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Available Pilots */}
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="container">
                                                <div className="border row justify-content-between">
                                                    {availablePilots.map((pilot, index) => (
                                                        <div key={index} className="col-6 p-1">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`pilot-${index}`}
                                                                    onChange={() => togglePilot(pilot)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`pilot-${index}`}>
                                                                    {pilot.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        }

                    </div>
                </div>

                {showInnerModal && (
                    <div
                        className="custom-modal"
                        style={{
                            position: 'absolute',
                            top: '0px',
                            right: '-420px',
                            background: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            zIndex: 3000,
                            padding: '10px',

                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center px-4 py-3">
                            <div>
                                <h3 style={{ fontWeight: 'bold' }}>{formValues.title === '' ? 'New Schedule' : formValues.title}</h3>
                            </div>

                            <div className="d-flex justify-content-center align-items-center">

                                <button onClick={() => setShowInnerModal(false)} className="btn d-flex align-items-center px-3 py-2">
                                    <div style={{ display: 'flex', alignItems: 'center', height: '1rem' }}>
                                        <IconX className='h-100' />
                                    </div>
                                </button>
                            </div>
                        </div>
                        <hr style={{ margin: 0, padding: 0 }} />
                        <Calendar schedule={schedule} />
                    </div>
                )}

            </Modal>

            <ModalGeneric
                title={"Edit Schedule"}
                text={"Schedule saved successfully."}
                showModal={showNoti}
                handleCloseModal={() => setShowNoti(false)}
            />

        </React.Fragment>



    )
}
