import React from 'react'
import Select from "react-select";

export const CustomSearchSelect = ({options, value, onChange}) => {
    return (
        <Select
            className='form-control select-reset p-0'
            options={options}
            value={value}
            isSearchable
            onChange={onChange}
            clearable={false}
        />
    )
}
