import React from "react";

const CalendarX = ({ className }) => {
    return (
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 448 512"
        className={className}
        >
            <path 
                fill="currentColor"
                d="M160 0l0 64 128 0 0-64 64 0 0 64 96 0 0 96L0 160 0 64l96 0L96 0l64 0zM0 192l448 0 0 320L0 512 0 192zM305 305l17-17L288 254.1l-17 17-47 47-47-47-17-17L126.1 288l17 17 47 47-47 47-17 17L160 449.9l17-17 47-47 47 47 17 17L321.9 416l-17-17-47-47 47-47z"
        />
        </svg>
                
        
            );
};

export default CalendarX;


