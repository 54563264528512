import axios from 'axios';
const {DRIVER_API_URL, DRIVER_API_TOKEN} = process.env;

const apiDriverApp = axios.create({
    baseURL: DRIVER_API_URL,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': DRIVER_API_TOKEN, 
      },
})

export {
    apiDriverApp
}