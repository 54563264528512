import React from "react";

const CalendarDay = ({ className }) => {
    return (
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 448 512"
        className={className}
        >
         
         <path 
         fill="currentColor"
         d="M160 0l0 64 128 0 0-64 64 0 0 64 96 0 0 96L0 160 0 64l96 0L96 0l64 0zM0 192l448 0 0 320L0 512 0 192zm192 64L64 256l0 128 128 0 0-128z"
        /></svg>
                
        
            );
};

export default CalendarDay;
