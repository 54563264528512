import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import IconFilterAlt from '../../../icons/filterIconAlt'
import LoadMask from '../../Utils/LoadMask'
import './styles.css';
import ModalTimeOffRequest from '../components/ModalTimeOffRequest';
import { apiDriverApp } from '../../../../libs/apiDriverApp';

export const TimeOff = () => {

  const [showModal, setShowModal] = useState(false);
  const [requests, setRequests] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [typeOptions, setTypeOptions] = useState([])
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = React.useState({
    month: '',
    status: '',
    type: '',
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getLeaveRequests = async () => {
    try {
      setIsLoading(true)
      const priorityOrder = {
        sent: 1,
        approved: 2,
        denied: 3
      };

      const { data } = await apiDriverApp.get('api/hr/leave/');
      const formattedRequests = data
        .map(item => ({
          id: item.id,
          name: `${item.employee.user.first_name} ${item.employee.user.last_name} (${item.employee.user.username})`,
          startDate: item.from_date.split('T')[0],
          endDate: item.to_date.split('T')[0],
          status: item.status,
          type: item.leave_type.name,
          dateRequested: item.latest_status.date_changed.split('T')[0],
          latestUser: item.latest_status.changed_by
        }))
        .sort((a, b) => priorityOrder[a.status] - priorityOrder[b.status]);
      setRequests(formattedRequests);
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  };

  const getLeaveTypes = async () => {
    try {
      const { data } = await apiDriverApp.get('/api/hr/leave-types/')
      const typeOptions = data.map(type => ({
        label: type.name,
        value: type.name
      }))
      setTypeOptions(typeOptions);
    } catch (error) {
      console.log(error)
    }
  }

  const monthOptions = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
  ];

  const statusOptions = [
    { label: 'Pending', value: 'sent' },
    { label: 'Approved', value: 'approved' },
    { label: 'Denied', value: 'denied' }
  ];

  const handleClick = (leave) => {
    setShowModal(true);
    setSelectedItem(leave)
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "sent":
        return { text: "Pending", backgroundColor: "#343A40" };
      case "approved":
        return { text: "Approved", backgroundColor: "#28A745" };
      case "denied":
        return { text: "Denied", backgroundColor: "#DC3545" };
      default:
        return { text: "Unknown", backgroundColor: "white" };
    }
  };

  const filtersConfig = [
    { id: 'select1', key: 'month', label: 'Select Month...', options: monthOptions },
    { id: 'select2', key: 'status', label: 'Select Status...', options: statusOptions },
    { id: 'select3', key: 'type', label: 'Select Type...', options: typeOptions },
  ];

  const filteredData = requests && requests.filter((item) => {
    const itemMonth = item.startDate.split('-')[1];
    const matchesSearch = item.name.toLowerCase().includes(search.toLowerCase())
    return (
      (!filters.month || itemMonth === filters.month) &&
      (!filters.status || item.status === filters.status) &&
      (!filters.type || item.type === filters.type) && matchesSearch
    ); 
  });

  useEffect(() => {
    getLeaveRequests(),
      getLeaveTypes();
  }, [])

  return (
    <div className="container w-100" >
      
      <LoadMask loading={isLoading} blur>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Time Off Requests</h3>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className='d-flex align-items-center w-50'>
            <div className="w-100">
              <input
                placeholder="Search..."
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className='px-0 justify-content-end align-items-center ml-2'>
              <div className='justify-content-end'>
                <Dropdown
                  className="custom-dropdown"
                  icon={null}
                  trigger={
                    <div style={{ display: 'flex', alignItems: 'center', height: '1.3rem' }}>
                      <IconFilterAlt className="h-100" />
                    </div>
                  }
                >
                  <Dropdown.Menu>
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="p-3"
                      style={{ width: 300 }}
                    >
                      {
                        filtersConfig.map(({ id, key, label, options }) => (
                          <select
                            key={id}
                            id={id}
                            className="form-control mb-2"
                            value={filters[key]}
                            onChange={(e) => handleFilterChange(key, e.target.value)}
                          >
                            <option value="">
                              {label}
                            </option>
                            {options.map((opt, index) => (
                              <option key={index} value={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </select>
                        ))
                      }
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <div style={{
          maxHeight: "700px",
          overflowY: "auto",
          overflowX: "hidden",
        }}>
          <table className="table table-bordered" >
            <thead className="bg-secondary text-white text-left">
              <tr>
                <th style={{ width: '25%' }}>Name</th>
                <th style={{ width: '25%' }}>Summary</th>
                <th style={{ width: '20%' }}>Status</th>
                <th style={{ width: '15%' }}>Approved By</th>
                <th style={{ width: '15%' }}>Type</th>
              </tr>
            </thead>
            <tbody>
              {
                filteredData ? filteredData.map(request => {
                  const { text, backgroundColor } = getStatusStyle(request.status);
                  return (
                    <tr key={request.id}>
                      <td>
                        {request.name}
                      </td>
                      <td >
                        {`${request.startDate} - ${request.endDate}`}
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer' }} onClick={() => handleClick(request)}>
                          <div style={{ backgroundColor: backgroundColor, padding: 3, color: 'white', borderRadius: 3, fontSize: 10, fontWeight: 'bold' }}>
                            {text}
                          </div>
                          <span>{request.dateRequested}</span>
                        </div>
                      </td>
                      <td>
                        {request.latestUser.includes(".")
                          ? request.latestUser 
                          : `${request.latestUser.split(" ")[0][0].toUpperCase()}. ${request.latestUser.split(" ")[1].charAt(0).toUpperCase() + request.latestUser.split(" ")[1].slice(1)}`}
                      </td>
                      <td>
                        {request.type}
                      </td>

                    </tr>
                  )
                }

                ) : (
                  <tr>
                    <td colSpan="4" className="text-center align-middle">
                      <div>No Data</div>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>

      </LoadMask>

      <ModalTimeOffRequest
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        request={selectedItem}
        getStatusStyle={getStatusStyle}
        // showComfirmation={showConfirmationModal}
        // title={selectedItem ? 'Edit Driver Shift' : 'Add Driver Shift'}
        // message={selectedItem ? 'Shift saved successfully.' : 'Shift added successfully.'}
        getLeaves={getLeaveRequests}
      />
    </div>
  )
}
