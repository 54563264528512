import React from 'react'
import Modal from 'react-responsive-modal';
import IconX from '../../../icons/x-icon';


export const ModalGeneric = ({ showModal, handleCloseModal, title, text }) => {

    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            showCloseIcon={false}
            styles={{ modal: { minWidth: '25%', borderRadius: '8px', marginTop: 50, padding: 0 } }}
        >
            <div className="d-flex row m-0 justify-content-between align-items-center pt-3">
                <div className='col-6'>
                    <h4 style={{ fontWeight: 'bold' }}>{title}</h4>
                </div>

                <div className="d-flex col-6 justify-content-end align-items-center">

                    <button className="btn border border-dark rounded shadow-sm px-3 py-2 d-flex align-items-center" onClick={handleCloseModal}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                            <IconX className='h-100'/>
                        </div>
                        <span className="ml-1" style={{ fontWeight: 'bold' }}>Close</span>
                    </button>
                </div>


            </div>

            <hr />

            <div className="container px-5">
                <div className="row mb-3">
                    <h5 style={{color:'#0A8400'}}>{text}</h5>
                </div>

            </div>

        </Modal>
    )
}
