import React, { useRef, useState } from 'react'
import { Link } from "react-router-dom";
import CalendarDay from '../../icons/calendar-day';
import CalendarDays from '../../icons/calendar-days';
import CalendarX from '../../icons/calendar-x';
import { settingsDriverSchedulesDailyShifts, settingsDriverSchedulesSchedules, settingsDriverSchedulesTimeOff } from '../../../routesConfiguration/paths';
import IconDownload from '../../icons/download';
import IconUpload from '../../icons/upload';
import './styles.css';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { apiDriverApp } from '../../../libs/apiDriverApp';

export const DriverSchedule = () => {

    const fileInputRef = useRef();

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return Promise.reject("No file selected");

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const arrayBuffer = e.target.result;
                    const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });

                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    const requiredHeaders = [
                        "Email",
                        "FirstName",
                        "Job",
                        "LastName",
                        "MobilePhone",
                        "PinCode",
                        "Username"
                    ];

                    const headers = jsonData[0];

                    const missingHeaders = requiredHeaders.filter(
                        (header) => !headers.includes(header)
                    );

                    if (missingHeaders.length > 0) {
                        reject(new Error(`The file uploaded is missing headers: ${missingHeaders.join(", ")}`));
                        return;
                    }

                    const invalidHeaders = headers.filter(
                        (header) => !requiredHeaders.includes(header)
                    );

                    if (invalidHeaders.length > 0) {
                        reject(new Error(`The file uploaded contains invalid headers: ${invalidHeaders.join(", ")}`));
                        return;
                    }

                    const excelData = jsonData.slice(1).map((row) => {
                        const obj = {};
                        headers.forEach((header, index) => {
                            obj[header] = row[index] || null;
                        });
                        return obj;
                    });

                    resolve(excelData);
                } catch (error) {
                    console.error("Error processing the file:", error);
                    reject(error);
                }
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const validateRequiredFields = (data, requiredFields) => {
        const invalidRecords = [];

        data.forEach((item, index) => {
            const isValid = requiredFields.every(field => item[field] && String(item[field]).trim() !== '');
            if (!isValid) {
                invalidRecords.push(`Row ${index + 1}`); 
            }
        });

        return invalidRecords; 
    };

    const validateMatchingRecords = (data, dbMap) => {
        const matchingRecords = [];
    
        data.forEach((item, index) => {
            const key = String(item.Username); 
            if (dbMap.has(key)) {
                matchingRecords.push(`Row ${index + 1}`);
            }
        });
    
        return matchingRecords; 
    };

    const createNewDrivers = async (dbMap, uploadedData) => {
        
        const requests = uploadedData.map(async (item) => {
            const match = dbMap.get(String(item.Username));

            if (!match) {
                const body = {
                    username: item.Username,
                    pin_code: item.PinCode,
                    email: item.Email || 'no@email.com',
                    first_name: item.FirstName,
                    last_name: item.LastName,
                    job: item.Job,
                    work_email: item.Email || 'no@email.com',
                    work_phone: item.MobilePhone,
                    mobile_phone: item.MobilePhone,
                };

                try {
                    await apiDriverApp.post('api/hr/employees/', body);
                    // console.log(`New driver created: ${item.Username}`);
                } catch (error) {
                    console.error(`Error processing employee ${item.Username}:`, error.response);
                    return { username: item.Username, error: error.message };
                }
            } else {
                console.log(`This driver already exists: ${item.Username}`);
            }
        });

        try {
            const results = await Promise.all(requests);
            const failed = results.filter(res => res && res.error);
            if (failed.length > 0) {
                Swal.fire('Some employees failed to upload', `Failed for: ${failed.map(e => e.username).join(', ')}`, 'error');
            } 

        } catch (error) {
            console.error("Error processing employees:", error);
           throw error
        }
    };


    const onFileUpload = async (event) => {
        try {
            const loadingAlert = Swal.fire({
                title: 'Uploading...',
                text: 'Please wait while the file is being uploaded and processed.',
                allowOutsideClick: false,
                allowEscapeKey: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });
            const results = await handleFileChange(event);
            console.log("Processed Data:", results);

            const invalidRecords = validateRequiredFields(results, ['Username', 'PinCode', 'Job']);
            if (invalidRecords.length > 0) {
                Swal.fire(
                    'Validation Error',
                    `The following records are missing required fields (Username or PinCode):\n${invalidRecords.join(', ')}`,
                    'error'
                );
                return;
            }

            const { data: dbDrivers } = await apiDriverApp.get('/api/hr/employees/');
            const dbMap = new Map(dbDrivers.map(item => [String(item.user.username), item]));

            const existingRecords = validateMatchingRecords(results, dbMap)
            if (existingRecords.length > 0) {
                await Swal.fire(
                    'Validation Error',
                    `The following records already exist in the database:\n${existingRecords.join(', ')}`,
                    'error'
                );
                return; 
            }

            await createNewDrivers(dbMap, results);
            Swal.close();
            Swal.fire('Success', 'The file was uploaded successfully', 'success');

        } catch (error) {
            console.error("Error during file upload:", error);
            Swal.close();
            if (error.response) {
                Swal.fire('Error', error.response.data.detail || 'An error occurred', 'error');
            } else if (error.request) {
                Swal.fire('Network Error', 'No response from server. Please try again later.', 'error');
            } else {
                Swal.fire('Error', error.message || 'An unexpected error occurred', 'error');
            }
        }
    };



    return (
        <div className="d-flex flex-wrap justify-content-center">
            <div className="col-12 col-xl-10 justify-content-center d-flex flex-wrap" style={{ gap: 70 }}>
                <button className='btn-hover' onClick={() => console.log('download')}>
                    <div className='row'>
                        <div style={{ height: '0.8rem' }}>
                            <IconDownload className="h-100" />
                        </div>
                        <div style={{ marginLeft: 5 }}>Download Driver List</div>
                    </div>
                </button>


                <div>
                    <button className='btn-hover' onClick={handleButtonClick}>
                        <div className='row'>
                            <div style={{ height: '0.8rem' }}>
                                <IconUpload className="h-100" />
                            </div>
                            <div style={{ marginLeft: 5 }}>Upload Driver List</div>
                        </div>
                    </button>
                    <input
                        type="file"
                        accept=".xls,.xlsx"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={onFileUpload}
                    />
                </div>

            </div>



            <div className="col-12 col-xl-10 justify-content-center d-flex flex-wrap" >
                <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 ">
                    <Link to={settingsDriverSchedulesDailyShifts}
                        style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#707070', color: '#000', margin: '2rem' }}
                        className="rounded shadow text-decoration-none py-3 px-3 w-60 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <CalendarDay className="h-100" />
                        </div>
                        <span className="mt-3">Daily Shifts</span>
                    </Link>
                </div>
                <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 ">
                    <Link to={settingsDriverSchedulesTimeOff}
                        style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#707070', color: '#000', margin: '2rem' }}
                        className="rounded shadow text-decoration-none py-3 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <CalendarX className="h-100" />
                        </div>
                        <span className="mt-3">Time Off</span>
                    </Link>
                </div>
                <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4">
                    <Link to={settingsDriverSchedulesSchedules}
                        style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#707070', color: '#000', margin: '2rem' }}
                        className="rounded shadow text-decoration-none py-3 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <CalendarDays className="h-100" />
                        </div>
                        <span className="mt-3">Schedules</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}


