import React, { useState, useEffect } from 'react';
import { CustomSearchSelect } from './CustomSearchSelect';
import { CustomHoursSelect } from './CustomHoursSelect';
import ToggleIcon from '../../../icons/toggleIcon';

export const WeeklySchedule = ({ schedule, setSchedule, options, isOpen }) => {

  const weeks = Object.keys(schedule);
  const days = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const [toggleState, setToggleState] = useState({});
  const [formKey, setFormKey] = useState(0);

  const handleSelectChange = (week, day, value) => {
    setSchedule((prev) => ({
      ...prev,
      [week]: {
        ...prev[week],
        [day]: {
          ...prev[week][day],
          name: value,
        },
      },
    }));
  };

  const handleToggle = (week, day) => {
    setToggleState((prev) => ({
      ...prev,
      [week]: {
        ...prev[week],
        [day]: prev[week] && prev[week][day] ? !prev[week][day] : true,
      },
    }));
  };

  const splitWeeks = (weeks, size) => {
    const result = [];
    for (let i = 0; i < weeks.length; i += size) {
      const group = weeks.slice(i, i + size);
      while (group.length < size) {
        group.push("");
      }
      result.push(group);
    }
    return result;
  };

  const weekGroups = splitWeeks(weeks, 2);

useEffect(() => {
    if (isOpen) {
      setFormKey((prev) => prev + 1); // Cambiar clave al abrir el formulario
    }
  }, [isOpen]);

  useEffect(() => {
    const initialToggleState = {};
    weeks.forEach((week) => {
      days.forEach((day) => {
        if (schedule[week] && schedule[week][day]) {
          const color = schedule[week][day].color;

          if (color === '#ffffff') {
            if (!initialToggleState[week]) {
              initialToggleState[week] = {};
            }
            initialToggleState[week][day] = true;
          }
        }
      });
    });
    setToggleState(initialToggleState);
  }, [formKey]); // Solo se ejecuta cuando cambia el formulario
  
  
  return (
    <div className="container mt-4">
      {weekGroups.map((weekGroup, groupIndex) => (
        <table
          key={groupIndex}
          className="table table-borderless align-middle mb-4"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <thead>
            <tr>
              <th style={{ width: "20%" }}></th> {/* Columna fija para días */}
              {weekGroup.map((week, index) => (
                <th key={index} className="text-center fw-bold" style={{ width: "40%" }}>
                  {week}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {days.map((day, dayIndex) => (
              <tr key={dayIndex}>
                <td className="fw-bold align-middle" style={{ fontSize: 14 }}>{day}</td>
                {weekGroup.map((week, weekIndex) => (
                  <td key={weekIndex} className="text-center">
                    {week ? (
                      <div style={{ width: "100%", display: "flex", alignItems: "center"}}>
                        {toggleState[week] && toggleState[week][day] ? (
                          <div style={{ flex: 1 }}>
                            <CustomHoursSelect
                              name={(schedule[week] && schedule[week][day] && schedule[week][day].name) || ""} 
                              onChange={handleSelectChange}
                              week={week}
                              day={day}
                            />
                          </div>
                        ) : (
                          <div style={{ flex: 1 }}>
                            <CustomSearchSelect
                              options={options}
                              value={(schedule[week] && schedule[week][day] && schedule[week][day].name) || ""} 
                              onChange={(option) => handleSelectChange(week, day, option.value)}
                            />
                          </div>

                        )}
                        <button
                          className="btn d-flex align-items-center p-0 ml-1"
                          onClick={() => handleToggle(week, day)}
                        >
                          <div style={{ display: "flex", alignItems: "center", height: "0.8rem", color:toggleState[week] && toggleState[week][day] ? '#d50032' : '#333f48' }}>
                            <ToggleIcon className="h-100" />
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div style={{ visibility: "hidden" }}> {/* Celda vacía*/}
                        <select className="form-control">
                          <option></option>
                          <option value="midday">Midday</option>
                          <option value="morning">Morning</option>
                          <option value="evening">Evening</option>
                        </select>
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
};
