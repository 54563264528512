import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Login from "./common/components/Login/LoginContainer";
import { ResetPassword } from "./common/components/reset_password";
import SuccessLoginOauth from "./successLoginOauthPage";

import {
    // Home
    home,
    // Reservations
    reservations,

    // Reservations > Airline
    reservationsAirline,
    reservationsAirlineBook,
    reservationsAirlineTrackTrip,
    reservationsAirlineTrackTripFlightFlights,
    reservationsAirlineTrackTripFlightFlightsId,
    reservationsAirlineSingleTripSearch,
    reservationsAirlineSingleTripSearchId,
    reservationsAirlineMultiTripSearch,
    reservationsAirlineMultiTripSearchList,
    reservationsAirlineUploads,
    reservationsAirlineUploadsId,

    // Reservations > Charter
    reservationsCharter,
    reservationsCharterQuotes,
    reservationsCharterQuotesNew,
    reservationsCharterQuotesId,
    reservationsCharterBookings,
    reservationsCharterBookingsId,
    reservationsCharterBookingsDetailId,
    reservationsCharterBookingsEditTripId,
    reservationsCharterReviews,
    reservationsCharterReviewsId,
    reservationsCharterAirportMeetingProcedures,

    // Reservations > Tours
    reservationsTours,
    reservationsToursId,

    // Reservations > Shuttles
    reservationsShuttles,
    reservationsShuttlesTracking,
    reservationsShuttlesTrackingId,
    reservationsShuttlesTrackingNew,

    // Reservations > Expense Tickets
    resrvationsExpenseTickets,

    // Reports
    reports,

    // Reports > Revenue
    reportsRevenue,

    // Reports > Trip Report
    reportsTripReport,
    reportsTripReportId,
    hotelPickupReport,

    // Marketing
    marketing,
    
    // Marketing > Promo Codes
    marketingPromoCodes,
    marketingPromoCodesId,
    marketingPromoCodesNew,
    
    // Marketing > Blog posts
    marketingBlogPosts,
    marketingBlogPostsNew,
    marketingBlogPostsId,

    //Hr > Applications
    hrApplications,
    hrApplicationsId,

    // Settings
    settings,

    // Settings > Tours
    settingsTours,
    settingsToursCreate,
    settingsToursId,

    // Settings > Accounts (Customers)
    settingsAccounts,
    settingsAccountsCompanies,
    settingsAccountsCompaniesId,
    settingsAccountsCustomers,
    settingsAccountsCustomersNew,
    settingsAccountsCustomersId,
    settingsAccountsPoints,
    settingsAccountsPointsId,

    // Settings > Rates
    settingsRates,
    settingsRatesLocations,
    settingsRatesLocationsCreate,
    settingsRatesLocationsId,
    settingsRatesLocationsIdUpdate,
    settingsRatesLocationsIdVehicles,
    settingsRatesLocationsIdZipCodes,
    settingsRatesLocationsIdPromotionalRates,
    settingsRatesLocationsIdPromotionalRatesSpecialRates,
    settingsRatesLocationsIdPromotionalRatesPointToPoint,
    settingsRatesLocationsIdPromotionalRatesPromoCodes,
    settingsRatesVehicles,
    settingsRatesVehiclesId,
    settingsRatesVehiclesNew,
    settingsRatesFees,
    settingsRatesFeesId,
    settingsRatesFeesNew,

    // Settings > Airline (Airline Crew)
    settingsAirlines,
    settingsAirlinesAccounts,
    settingsAirlinesAccountsId,
    settingsAirlinesAccountsIdRouteNew,
    settingsAirlinesAccountsIdRouteIdUpdate,
    settingsAirlinesAccountsIdRouteId,
    settingsAirlinesAccountsIdAccountGroupsNew,
    settingsAirlinesAccountsIdAccountGroupsId,
    settingsAirlinesZones,
    settingsAirlinesZonesId,
    settingsAirlinesZonesNew,
    settingsAirlinesAirports,
    settingsAirlinesContacts,
    settingsAirlinesLocations,
    settingsAirlinesOwners,

    // Settings > User Accounts
    settingsUserAccounts,
    settingsUserAccountsUsers,
    settingsUserAccountsUsersNew,
    settingsUserAccountsUsersId,
    settingsUserAccountsPermissions,
    settingsUserAccountsPermissionsNew,
    settingsUserAccountsPermissionsId,

    // Settings > Driver Schedules
    settingsDriverSchedules,
    settingsDriverSchedulesDailyShifts,
    settingsDriverSchedulesTimeOff,
    settingsDriverSchedulesSchedules,

    // No modified routes
    refundId,
    priceCheck,
    shuttlesId,
    liveryShuttles,
    liveryShuttlesCompany,
    airlineLivery,
    airlineLiveryDetails,
    login,
    passwordReset,
    passwordResetToken,
    successToken,
    reservationsCharterReviewsPassenger,
    reservationsCharterReviewsPassengerId,
    reservationsCharterReviewsBooking,
    reservationsCharterReviewsBookingId,
    reservationsCharterReviewsService,
    reservationsCharterReviewsServiceId,
} from "./routesConfiguration/paths";

// GroupRates
import {
    Grid as GRGRid,
    Create as GRCreate,
    Update as GRUpdate,
    Detail as GRDetail,
    VehiclesUpdate,
    ZipCodesUpdate,
    PromotionalRatesUpdate,
    SpecialRatesUpdate,
    PointToPointUpdate,
    PromoCodeUpdate,
} from "./common/components/GroupRates";
import {
    Grid as VHGrid,
    Create as VHCreate,
    Update as VHUpdate,
} from "./common/components/Vehicle";
import {
    Grid as USGrid,
    Create as USCreate,
    Update as USUpdate,
} from "./common/components/User";
import {
    Grid as CLGrid,
    Create as CLCreate,
    Update as CLUpdate,
} from "./common/components/Client";
import {
    ApplyGrid,
    ApplyDetail
} from "./common/components/apply";
import {
    Grid as PCGrid,
    Create as PCCreate,
    Update as PCUpdate,
} from "./common/components/PromoCode";
import {
    Grid as TRGrid,
    Create as TRCreate,
    Update as TRUpdate,
} from "./common/components/Tour";
import HotelTripReport from "./common/components/hotelTripsReport/HotelTripReport.js" 
import TripSearch from "./common/components/hotelTripsReport/TripSearch.js";
import {
    Grid as TripsReportGrid,
    Detail as TripReportDetail,
} from "./common/components/TripsReport";
import {
    List as TRResList,
    Detail as TRResDetail,
} from "./common/components/TourReservations";
import {
    Grid as COMPGrid,
    Update as COMPUpdate,
} from "./common/components/Company";
import Revenue from "./common/components/Revenue";
import {
    Grid as MeetGrid,
} from "./common/components/meetingProcedure";
import {
    Grid as ADCGrid,
    Create as ADCCreate,
    Update as ADCUpdate,
} from "./common/components/additionalCharges";

import TripEditionContainer from "./common/components/tripEdition";
import liveryEdition from './common/components/liveryEdition';
import liveryCreate from './common/components/liveryEdition/create';
import AirlineCrew from './common/components/airlineCrew';
import AirlineCrewFlights from './common/components/airlineCrew/flights';
import AirlineCrewFlightDetails from './common/components/airlineCrew/details';
import ExpenseTickets from "./common/components/ExpenseTickets/ExpenseTickets.jsx";

import AirlineNav from './common/components/airlineNav';
import CharterNav from './common/components/charterNav';
import CustomersNav from './common/components/customersNav';
import RatesNav from './common/components/ratesNav';
import UserAccountsNav from './common/components/userAccountsNav';
import ReservationsNav from "./common/components/reservationsNav";
import ReportsNav from "./common/components/reportsNav";
import MarketingNav from "./common/components/MarketingNav";
import SettingsNav from "./common/components/SettingsNav";
import ReviewsNav from "./common/components/ReviewsNav";
// Pending Reservations
import {
    ReservationList,
} from "./common/components/Reservations";
import {
    LegacyReservationDetail,
} from "./common/components/legacy-Reservations";

import {
    RefundList,
} from "./common/components/Refund";

import Privado from "./common/components/Privado/Privado";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

import {
    Create as CreateBlogPost,
    Grid as BlogPostGrid,
    Update as BlogPostUpdate
} from "./common/components/BlogPost";

// Crew admin
import {
    Grid as CrewAdmin,
    Update as UpdateCrewAdmin,
    Create as CreateCrewAdmin
} from './common/components/crewAdmin';
import {
    GridCompanies,
    DetailsCompanies
} from './common/components/crewAdmin/companies';
import {
    GridZones,
    CreateZones,
    UpdateZones,
} from './common/components/crewAdmin/zones';

import PriceCheck from './common/components/priceCheck';
import CrewAirports from './common/components/crewAdmin/airports';
import CrewContacts from './common/components/crewAdmin/contacts';
import CrewLocations from './common/components/crewAdmin/locations';
import CrewAccountOwner from './common/components/crewAdmin/account_owner';
import CreateAirlineRoute from './common/components/crewAdmin/companies/details/routes/create';
import UpdateAirlineRoute from './common/components/crewAdmin/companies/details/routes/update';
import ReadAirlineRoute from './common/components/crewAdmin/companies/details/routes/details';
import CreateAccountGroup from './common/components/crewAdmin/companies/details/accountGroups/create';
import UpdateAccountGroup from './common/components/crewAdmin/companies/details/accountGroups/update';

// Schedule
import {
    Create as CreateSchedule,
    Detail as DetailSchedule
} from './common/components/schedule';

// Quotes
import {
    QuoteList,
    QuoteCreate,
    QuoteUpdate,
} from "./common/components/quotes";

{/* Reviews */}
import {
    Grid as GridReviews,
    Details as DetailReviews
} from './common/components/reviews';

{/* Point details */}
import {
    Grid as usersPoints,
    Details as pointsDetails,
} from './common/components/points';

// Roles
import {
    Grid as RolesGrid,
    Update as RolesUpdate,
    Create as RolesCreate
} from './common/components/roles';

// Shuttles
import ShuttleNav from './common/components/shuttleNav';
import {
    List as ShuttleList,
    Detail as ShuttleDetail,
    Create as ShuttleCreate,
    Update as ShuttleUpdate,
    LiveryShuttleCompanySelect,
    LiveryShuttle,
} from "./common/components/shuttles";

//Cart
import {
    Detail as CartDetails,
} from './common/components/customerCart';

import {
    List as AirlineLiveryList,
    CompanySelect as AirlineLiveryCompanySelect,
} from "./common/components/airlineLivery";

import "../assets/fonts/fonts.css";
import { DriverSchedule } from "./common/components/DriverSchedule/DriverSchedule.jsx";
import {DailyShifts} from "./common/components/DriverSchedule/nav/DailyShifts.jsx";
import {Schedules} from "./common/components/DriverSchedule/nav/Schedules.jsx";
import {TimeOff} from "./common/components/DriverSchedule/nav/TimeOff.jsx";

require("../../node_modules/font-awesome/css/font-awesome.css");
require("../../node_modules/bootstrap/dist/css/bootstrap.css");
require("../style/index.css");

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Redirect
                    exact
                    from="/reservations/charter/bookings/edit-trip"
                    to="/reservations/charter/bookings"
                />
                <Redirect
                    exact
                    from="/reservations/charter/bookings/detail"
                    to="/reservations/charter/bookings"
                />
                <Redirect
                    exact
                    from="/settings/airlines/accounts/:airlineId/route"
                    to="/settings/airlines/accounts/:airlineId"
                />

                <Redirect
                    exact
                    from="/settings/airlines/accounts/:airlineId/account-groups"
                    to="/settings/airlines/accounts/:airlineId"
                />

                <PublicRoute exact path={login} component={Login} />

                <ProtectedRoute exact path={settings} component={SettingsNav} />
                {/* GroupRates */}
                <ProtectedRoute path={settingsRatesLocationsCreate} component={GRCreate} />
                <ProtectedRoute path={settingsRatesLocationsIdUpdate} component={GRUpdate}/>
                <ProtectedRoute exact path={settingsRatesLocations} component={GRGRid} />
                <ProtectedRoute exact path={settingsRatesLocationsId} component={GRDetail} />
                <ProtectedRoute exact path={settingsRatesLocationsIdVehicles} component={VehiclesUpdate} />
                <ProtectedRoute exact path={settingsRatesLocationsIdZipCodes} component={ZipCodesUpdate} />
                <ProtectedRoute exact path={settingsRatesLocationsIdPromotionalRates} component={PromotionalRatesUpdate} />
                <ProtectedRoute exact path={settingsRatesLocationsIdPromotionalRatesSpecialRates} component={SpecialRatesUpdate} />
                <ProtectedRoute exact path={settingsRatesLocationsIdPromotionalRatesPointToPoint} component={PointToPointUpdate} />
                <ProtectedRoute exact path={settingsRatesLocationsIdPromotionalRatesPromoCodes} component={PromoCodeUpdate} />

                <ProtectedRoute
                    exact
                    path={reservations}
                    component={ReservationsNav}
                />

                <ProtectedRoute
                    exact
                    path={resrvationsExpenseTickets}
                    component={ExpenseTickets}
                />

                {/* Livery Edition Routes */}
                <ProtectedRoute exact path={reservationsAirlineSingleTripSearch} component={liveryEdition} />
                <ProtectedRoute exact path={reservationsAirlineBook} component={liveryCreate} />

                <ProtectedRoute path={reservationsCharterAirportMeetingProcedures} component={MeetGrid} />

                <ProtectedRoute path={settingsRatesVehiclesNew} component={VHCreate} />
                <ProtectedRoute
                    path={settingsRatesVehiclesId}
                    component={VHUpdate}
                />
                <ProtectedRoute path={settingsRatesVehicles} component={VHGrid} />

                <ProtectedRoute path={settingsUserAccountsUsersNew} component={USCreate} />
                <ProtectedRoute path={settingsUserAccountsUsersId} component={USUpdate} />
                <ProtectedRoute path={settingsUserAccountsUsers} component={USGrid} />

                <ProtectedRoute
                    exact
                    path={settingsAccountsCustomers}
                    component={CLGrid}
                />
                <ProtectedRoute
                    exact
                    path={settingsAccountsCustomersNew}
                    component={CLCreate}
                />
                <ProtectedRoute
                    exact
                    path={settingsAccountsCustomersId}
                    component={CLUpdate}
                />

                <ProtectedRoute exact path={settingsAccountsCompanies} component={COMPGrid} />
                <ProtectedRoute
                    exact
                    path={settingsAccountsCompaniesId}
                    component={COMPUpdate}
                />

                <ProtectedRoute
                    exact
                    path={settingsDriverSchedules}
                    component={DriverSchedule}
                />

                <ProtectedRoute
                    exact
                    path={settingsDriverSchedulesDailyShifts}
                    component={DailyShifts}
                />

                <ProtectedRoute
                    exact
                    path={settingsDriverSchedulesTimeOff}
                    component={TimeOff}
                />

                <ProtectedRoute
                    exact
                    path={settingsDriverSchedulesSchedules}
                    component={Schedules}
                />

                <ProtectedRoute
                    exact
                    path={marketing}
                    component={MarketingNav}
                />

                <ProtectedRoute
                    exact
                    path={hrApplications}
                    component={ApplyGrid}
                    applyContainer={false}
                />

                <ProtectedRoute
                    exact
                    path={hrApplicationsId}
                    component={ApplyDetail}
                />

                <ProtectedRoute
                    exact
                    path={marketingPromoCodes}
                    component={PCGrid}
                />
                <ProtectedRoute
                    exact
                    path={marketingPromoCodesNew}
                    component={PCCreate}
                />
                <ProtectedRoute
                    exact
                    path={marketingPromoCodesId}
                    component={PCUpdate}
                />

                <ProtectedRoute exact path={settingsTours} component={TRGrid} />
                <ProtectedRoute
                    exact
                    path={settingsToursCreate}
                    component={TRCreate}
                />
                <ProtectedRoute
                    exact
                    path={settingsToursId}
                    component={TRUpdate}
                />

                <ProtectedRoute
                    exact
                    path={reservationsCharterBookings}
                    component={ReservationList}
                />
                <ProtectedRoute
                    exact
                    path={reservationsCharterBookingsDetailId}
                    component={LegacyReservationDetail}
                />

                {/*  Trip edition  */}
                <ProtectedRoute
                    exact
                    path={reservationsCharterBookingsEditTripId}
                    component={TripEditionContainer}
                />
                {/* cart routes */}
                <ProtectedRoute
                    path={reservationsCharterBookingsId}
                    component={CartDetails}
                />

                <ProtectedRoute
                    exact
                    path={reservationsTours}
                    component={TRResList}
                />
                <ProtectedRoute
                    exact
                    path={reservationsToursId}
                    component={TRResDetail}
                />

                <ProtectedRoute exact path={reports} component={ReportsNav} />

                <ProtectedRoute
                    exact
                    path={reportsTripReport}
                    component={HotelTripReport}
                />
                 <ProtectedRoute
                    exact
                    path={hotelPickupReport}
                    component={TripSearch}
                />
                <ProtectedRoute
                    exact
                    path={reportsTripReportId}
                    component={TripReportDetail}
                />

                <ProtectedRoute exact path={settingsAccounts} component={CustomersNav} />
                <ProtectedRoute exact path={settingsRates} component={RatesNav} />
                <ProtectedRoute exact path={settingsUserAccounts} component={UserAccountsNav} />
                <ProtectedRoute exact path={reservationsCharter} component={CharterNav}/>
                <ProtectedRoute exact path={reservationsAirline} component={AirlineNav}/>
                <ProtectedRoute exact path={reservationsAirlineTrackTrip} component={AirlineCrew} />
                <ProtectedRoute exact path={reservationsAirlineTrackTripFlightFlights} component={AirlineCrewFlights} />
                <ProtectedRoute exact path={reservationsAirlineTrackTripFlightFlightsId} component={AirlineCrewFlightDetails} />

                <ProtectedRoute exact path={reportsRevenue} component={Revenue} />
                <ProtectedRoute exact path={marketingBlogPosts} component={BlogPostGrid} />
                <ProtectedRoute exact path={marketingBlogPostsNew} component={CreateBlogPost} />
                <ProtectedRoute exact path={marketingBlogPostsId} component={BlogPostUpdate} />
                <ProtectedRoute exact path={refundId} component={RefundList} />
                <ProtectedRoute exact path={settingsRatesFees} component={ADCGrid} />
                <ProtectedRoute exact path={settingsRatesFeesNew} component={ADCCreate} />
                <ProtectedRoute exact path={settingsRatesFeesId} component={ADCUpdate} />

                {/* Tracker Shuttler */}
                <ProtectedRoute exact path={reservationsShuttles} component={ShuttleNav} />
                <ProtectedRoute exact path={liveryShuttles} component={LiveryShuttleCompanySelect} />
                <ProtectedRoute exact path={liveryShuttlesCompany} component={LiveryShuttle} />
                <ProtectedRoute exact path={reservationsShuttlesTracking} component={ShuttleList} />
                <ProtectedRoute exact path={shuttlesId} component={ShuttleDetail} />
                <ProtectedRoute exact path={reservationsShuttlesTrackingNew} component={ShuttleCreate} />
                <ProtectedRoute exact path={reservationsShuttlesTrackingId} component={ShuttleUpdate} />

                {/* Admin airline, airport and meeting procedure */}
                <ProtectedRoute exact path={priceCheck} component={PriceCheck} />
                <ProtectedRoute exact path={settingsAirlines} component={CrewAdmin} />
                <ProtectedRoute exact path={settingsAirlinesAccounts} component={GridCompanies} />
                <ProtectedRoute exact path={settingsAirlinesAccountsId} component={DetailsCompanies} applyContainer={false}/>
                <ProtectedRoute exact path={settingsAirlinesZones} component={GridZones} />
                <ProtectedRoute exact path={settingsAirlinesZonesNew} component={CreateZones}/>
                <ProtectedRoute exact path={settingsAirlinesZonesId} component={UpdateZones} />
                <ProtectedRoute exact path={settingsAirlinesAirports} component={CrewAirports} />
                <ProtectedRoute exact path={settingsAirlinesContacts} component={CrewContacts} />
                <ProtectedRoute exact path={settingsAirlinesLocations} component={CrewLocations} />
                <ProtectedRoute exact path={settingsAirlinesOwners} component={CrewAccountOwner}/>
                <ProtectedRoute exact path={settingsAirlinesAccountsIdRouteNew} component={CreateAirlineRoute}/>
                <ProtectedRoute exact path={settingsAirlinesAccountsIdRouteIdUpdate} component={UpdateAirlineRoute}/>
                <ProtectedRoute exact path={settingsAirlinesAccountsIdRouteId} component={ReadAirlineRoute}/>
                <ProtectedRoute exact path={settingsAirlinesAccountsIdAccountGroupsNew} component={CreateAccountGroup}/>
                <ProtectedRoute exact path={settingsAirlinesAccountsIdAccountGroupsId} component={UpdateAccountGroup}/>

                {/* Airline Livery */}
                <ProtectedRoute exact path={reservationsAirlineMultiTripSearch} component={AirlineLiveryCompanySelect} />
                <ProtectedRoute exact path={reservationsAirlineMultiTripSearchList} component={AirlineLiveryList} />

                {/* Schedules */}
                <ProtectedRoute exact path={reservationsAirlineUploads} component={CreateSchedule} applyContainer={false}/>
                <ProtectedRoute exact path={reservationsAirlineUploadsId} component={DetailSchedule} />

                {/* Quotes */}
                <ProtectedRoute exact path={reservationsCharterQuotes} component={QuoteList} />
                <ProtectedRoute exact path={reservationsCharterQuotesNew} component={QuoteCreate} />
                <ProtectedRoute exact path={reservationsCharterQuotesId} component={QuoteUpdate} />

                {/* Reviews */}
                {/* <ProtectedRoute exact path={reservationsCharterReviews} component={GridReviews} /> */}
                <ProtectedRoute
                    exact
                    path={reservationsCharterReviews}
                    component={ReviewsNav}
                />
                {/* <ProtectedRoute
                    exact
                    path={reservationsCharterReviewsPassenger}
                    component={GridReviews.GridPassenger}
                /> */}
                {/* <ProtectedRoute
                    exact
                    path={reservationsCharterReviewsPassengerId}
                    component={DetailReviews.DetailPassenger}
                /> */}

                <ProtectedRoute
                    exact
                    path={reservationsCharterReviewsBooking}
                    component={GridReviews.GridBooking}
                />
                <ProtectedRoute
                    exact
                    path={reservationsCharterReviewsBookingId}
                    component={DetailReviews.DetailBooking}
                />

                <ProtectedRoute
                    exact
                    path={reservationsCharterReviewsService}
                    component={GridReviews.GridService}
                />
                <ProtectedRoute
                    exact
                    path={reservationsCharterReviewsServiceId}
                    component={DetailReviews.DetailService}
                />

                <ProtectedRoute exact path={reservationsCharterReviewsId} component={DetailReviews} />

                {/* Point Details */}
                <ProtectedRoute exact path={settingsAccountsPoints} component={usersPoints}/>
                <ProtectedRoute exact path={settingsAccountsPointsId} component={pointsDetails}/>

                {/* Roles */}
                <ProtectedRoute exact path={settingsUserAccountsPermissions} component={RolesGrid} />
                <ProtectedRoute exact path={settingsUserAccountsPermissionsNew} component={RolesCreate} />
                <ProtectedRoute exact path={settingsUserAccountsPermissionsId} component={RolesUpdate} />

                <PublicRoute exact path={passwordReset} component={ResetPassword} />
                <PublicRoute exact path={passwordResetToken} component={ResetPassword} />

                <PublicRoute exact path={successToken} component={SuccessLoginOauth}/>
                <ProtectedRoute path={home} component={Privado} />
            </Switch>
        </div>
    </div>
);
