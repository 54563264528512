import React from "react";

const RotateArrow = ({ className }) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512"
            className={className}
            >
                <path
                    fill="currentColor" 
                    d="M369.1 142.9L312 200l24 24 160 0 0-160L472 40 414.4 97.6s0 0 0 0c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0l-45.3-45.3c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0c0 0 0 0 0 0z"
        />
        </svg>
            );
};

export default RotateArrow;
