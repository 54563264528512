import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal';
import IconFloppyDisk from '../../../icons/floppy-disk';
import { SliderPicker } from 'react-color';
import Select from "react-select";
import swal from 'sweetalert2';
import { apiDriverApp } from '../../../../libs/apiDriverApp';
import { ModalGeneric } from './ModalGeneric';

export const ModalForm = ({ showModal, handleCloseModal, initialValues, title, message, getShifts }) => {

  const [formValues, setFormValues] = useState({
    title: '',
    startTime: null,
    endTime: null,
    color: '#ffffff',
    station: '',
  });

  const handleChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (initialValues) {
      setFormValues({
        title: initialValues.name || '',
        startTime: initialValues.hour_from || null,
        endTime: initialValues.hour_to || null,
        color: initialValues.color || '#ffffff',
        station: initialValues.station.toUpperCase() || '',
      });
    } else {
      setFormValues({
        title: '',
        startTime: null,
        endTime: null,
        color: '#ffffff',
        station: '',
      });
    }
  }, [initialValues]);

  const stations = ["WAS", "BWI", "MCI", "LAX", "ALL"];

  const [showNoti, setShowNoti] = useState(false)

  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0");
    return { value: parseFloat(i.toFixed(2)), label: `${hour}:00` };
  });

  const handleSubmit = async () => {
    // Validaciones iniciales
    if (!formValues || formValues.title === '' || !formValues.startTime) {
      swal("Empty Fields", 'Please verify that all fields are filled', "error");
      return;
    }
  
    try {
      if (initialValues) {
        // Editar
        console.log('Forma para editar');
        const { data } = await apiDriverApp.put(`api/hr/shifts/${initialValues.id}/`, {
          name: formValues.title,
          hour_from: formValues.startTime,
          hour_to: formValues.endTime,
          station: formValues.station.toLowerCase(),
          color: formValues.color,
        });
        console.log(data);
      } else {
        // Crear
        console.log('Forma para crear');
        const { data } = await apiDriverApp.post('api/hr/shifts/', {
          name: formValues.title,
          hour_from: formValues.startTime,
          hour_to: formValues.endTime,
          station: formValues.station.toLowerCase(),
          color: formValues.color,
        });
        console.log(data);
      }
  
      // Si la solicitud tiene éxito
      setShowNoti(true);
      getShifts();
      handleCloseModal();
    } catch (error) {
      console.error("Error durante la solicitud:", error.response);
  
      if (error.response) {
        swal("Error", error.response.data.detail || "An error occurred", "error");
      } else if (error.request) {
        swal("Network Error", "No response from server. Please try again later.", "error");
      } else {
        swal("Error", "An unexpected error occurred", "error");
      }
    }
  };
  


  return (
    <React.Fragment>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        showCloseIcon={false}
        styles={{ modal: { minWidth: '30%', borderRadius: '8px', marginTop: 50, padding: 0 } }}
      >
        <div className="d-flex row m-0 justify-content-between align-items-center px-4 pt-3">
          <div className='col-6'>
            <h4 style={{ fontWeight: 'bold' }}>{initialValues ? 'Edit Driver Shift' : 'Add Driver Shift'}</h4>
          </div>

          <div className="d-flex justify-content-between align-items-center">

            <button onClick={handleCloseModal} className="btn d-flex align-items-center rounded shadow-sm px-3 py-2 me-3" style={{ backgroundColor: '#D50032', color: 'white' }}>
              <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                X
              </div>
              <span className="ml-1">Close</span>
            </button>

            <button className="btn border border-dark rounded shadow-sm px-3 py-2 d-flex align-items-center ml-3" onClick={handleSubmit}>
              <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                <IconFloppyDisk className='h-100' />
              </div>
              <span className="ml-1" style={{ fontWeight: 'bold' }}>Save</span>
            </button>
          </div>


        </div>

        <hr />

        <div className="container px-5">
          <div className="row mb-3">
            <div className='col-12'>
              <h6>Title</h6>
              <input
                placeholder='Midday'
                className='form-control'
                value={formValues.title}
                onChange={(e) => handleChange('title', e.target.value)}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <h6>Start Time</h6>
              <Select
                options={hours}
                value={formValues.startTime}
                onChange={(option) => handleChange('startTime', option.value)}
                placeholder="00:00"
              />
            </div>
            <div className="col-md-6 ">
              <h6>End Time</h6>
              <Select
                options={hours}
                value={formValues.endTime}
                onChange={(option) => handleChange('endTime', option.value)}
                placeholder="12:00"
              />
            </div>
          </div>

          <div className="row mb-3 align-items-center">
            <div className='col-8'>
              <h6>Color</h6>
              <SliderPicker
                color={formValues.color}
                onChange={(updatedColor) => handleChange('color', updatedColor.hex)}
              />
            </div>
            <div className='col-4'>
              <input
                value={formValues.color}
                onChange={(e) => handleChange('color', e.target.value)}
                className="form-control"
              />

            </div>
          </div>

          <div className="row mb-3">
            <div className='col-12'>
              <h6>Stations</h6>
              <div style={{ border: "1px solid #ccc", padding: "2px", borderRadius: "4px" }}>
                {stations.map((station) => (
                  <div
                    key={station}
                    onClick={() => {
                      const updatedStation = formValues.station.toUpperCase() === station ? '' : station;
                      handleChange('station', updatedStation);
                    }}
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      backgroundColor: formValues.station.toUpperCase() === station ? '#cce5ff' : 'white',
                      transition: "background-color 0.3s",
                    }}
                  >
                    {station}
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>

      </Modal>
      <ModalGeneric
        title={title}
        text={message}
        showModal={showNoti}
        handleCloseModal={() => setShowNoti(false)}
      />
    </React.Fragment>

  )
}
