import React, { useEffect, useState } from 'react'
import IconEdit from '../../../icons/edit'
import { IconTrashAlt } from '../../../icons/trash'
import { ModalForm } from '../components/ModalForm'
import { ModalGeneric } from '../components/ModalGeneric'
import { ModalDelete } from '../components/ModalDelete'
import { apiDriverApp } from '../../../../libs/apiDriverApp'

export const DailyShifts = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [shifts, setShifts] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null)
  const [search, setSearch] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmation(false)
  };

  const getShifts = async () => {
    const { data } = await apiDriverApp.get('api/hr/shifts/');
    setShifts(data)
  }

  useEffect(() => {
    getShifts()
  }, [])

  const formatTime = (hour) => {
    const totalMinutes = Math.round(hour * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  const filteredShifts = shifts && shifts.filter((shift) => {
    const matchesSearch = shift.name
      .toLowerCase()
      .includes(search.toLowerCase());
    return matchesSearch;
  });

  return (
    <div className="container w-75">
      {/* Título y botón */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Driver Shifts</h3>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="w-50">
          <input
            placeholder="Search..."
            className="form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

        </div>

        <button className="btn border border-dark rounded shadow-sm" onClick={() => { setSelectedItem(null); setShowModal(true) }}>
          <label style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer' }}>+ New</label>
        </button>

      </div>

      {/* Tabla */}
      <table className="table table-bordered">
        <thead className="bg-secondary text-white text-center">
          <tr>
            <th style={{ width: '5%' }}></th>
            <th style={{ width: '35%' }}>Time</th>
            <th style={{ width: '35%' }}>Title</th>
            <th style={{ width: '25%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            filteredShifts ? filteredShifts.map(item => (
              <tr key={item.id}>
                <td>
                  <div style={{ width: '15px', height: '15px', backgroundColor: item.color }}></div>
                </td>
                <td className="text-center align-middle">{`${formatTime(item.hour_from)} - ${formatTime(item.hour_to)}`}</td>
                <td className="text-center align-middle">{item.name}</td>
                <td>
                  <div className="d-flex justify-content-center align-items-center">
                    {/* Botón Edit */}
                    <button className="btn d-flex align-items-center p-0 me-3" onClick={() => { setSelectedItem(item); setShowModal(true); }}>
                      <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                        <IconEdit className='h-100' />
                      </div>
                      <span className="ml-1">Edit</span>
                    </button>
                    {/* Botón Delete */}
                    <button className="btn d-flex align-items-center p-0 ml-3" onClick={() => { setSelectedItem(item); setShowDeleteModal(true); }}>
                      <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                        <IconTrashAlt className='h-100' />
                      </div>
                      <span className="ml-1">Delete</span>
                    </button>
                  </div>
                </td>
              </tr>

            )) : (
              <tr>
                <td colSpan="4" className="text-center align-middle">
                  <div>No Data</div>
                </td>
              </tr>
            )
          }

        </tbody>
      </table>
      <ModalForm
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        initialValues={selectedItem ? selectedItem : null}
        showComfirmation={showConfirmationModal}
        title={selectedItem ? 'Edit Driver Shift' : 'Add Driver Shift'}
        message={selectedItem ? 'Shift saved successfully.' : 'Shift added successfully.'}
        getShifts={getShifts}
      />

      <ModalDelete
        showModal={showDeleteModal}
        handleCloseModal={handleCloseDeleteModal}
        item={selectedItem}
        getShifts={getShifts}
        title='Delete Driver Shift'
        text=' This will also remove the shift from any
               existing schedule and driver assignments.
              Are you sure you want to continue?'
      />

    </div>


  )
}
